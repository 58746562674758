<template>
    <div class="dtn dtn-futures">

        <div class="loading" v-if="!status.ready">
            Loading...
        </div>

        <div class="form-check mt-4">
            <input class="form-check-input" type="checkbox" value="" id="showAllColumns" v-model="showAllColumns">
            <label class="form-check-label" for="showAllColumns">
                Show all columns <small>(Scroll left and right for more columns)</small>
            </label>
        </div>

        <div v-for="item in priceHistory">
            <h3>{{item.description}}</h3>
            <table class="table table-sm">
                <thead class="thead-light">
                <tr>
                    <th>Start Date</th>
                    <th>Open</th>
                    <th>High</th>
                    <th>Low</th>
                    <th>Close</th>
                    <th>Volume</th>
                    <th>OI</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="price in item.historicPrices">
                    <td>{{price.startDateTime}}</td>
                    <td>
                        <span v-if="price.open">{{price.open['text']}}</span>
                        <span v-else class="text-danger">No&nbsp;Value</span>
                    </td>
                    <td>
                        <span v-if="price.high">{{price.high['text']}}</span>
                        <span v-else class="text-danger">No&nbsp;Value</span>
                    </td>
                    <td>
                        <span v-if="price.low">{{price.low['text']}}</span>
                        <span v-else class="text-danger">No&nbsp;Value</span>
                    </td>
                    <td>
                        <span v-if="price.close">{{price.close['text']}}</span>
                        <span v-else class="text-danger">No&nbsp;Value</span>
                    </td>
                    <td>{{price.volume}}</td>
                    <td>{{price.oi}}</td>
                </tr>
                </tbody>

            </table>
        </div>

        <div v-if="status.ready">

            <div class="table-responsive">
                <table class="table table-sm table-striped table-dtn table-dtn-futures mt-4">
                    <thead>
                    <tr class="thead-dark">
                        <th>Month</th>
                        <th class="text-right">Previous</th>
                        <th class="text-right">Last</th>
                        <th class="text-right">Change</th>
                        <th v-if="showAllColumns" class="text-right">%</th>
                        <th class="text-right">Open</th>
                        <th v-if="showAllColumns" class="text-right">Previous</th>
                        <th class="text-right">High</th>
                        <th class="text-right">Low</th>

                        <th v-if="showAllColumns" class="text-right">Open Interest</th>
                        <th v-if="showAllColumns" class="text-right">Ask</th>
                        <th v-if="showAllColumns" class="text-right">Ask Time</th>
                        <th v-if="showAllColumns" class="text-right">Bid</th>
                        <th v-if="showAllColumns" class="text-right">Bid Time</th>
                        <th v-if="showAllColumns" class="text-right">Settle Price</th>
                        <th v-if="showAllColumns" class="text-right">Contract High</th>
                        <th v-if="showAllColumns" class="text-right">Contract Low</th>
                        <th v-if="showAllColumns" class="text-right">Cum Volume</th>
                        <th v-if="showAllColumns" class="text-right">Expiration Date</th>
                        <th v-if="showAllColumns" class="text-right">Settle Date</th>

                        <th class="text-right">Trade Time</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <template v-for="commodity in quotes">
                        <thead>
                        <tr>
                            <th colspan="11">{{commodity.label}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="quote in commodity.data">
                            <th>{{quote.month}}</th>
                            <td class="text-right">{{quote.previous | dtnNumber | round(4, true)}}</td>
                            <td class="text-right">{{quote.last | dtnNumber | round(4, true)}}</td>
                            <td class="text-right" v-bind:class="upDown(quote.change)">{{quote.change | dtnNumber | round(4, true)}}</td>
                            <td v-if="showAllColumns" class="text-right" v-bind:class="upDown(parseFloat(quote.change.number) / parseFloat(quote.last.number) * 1000)">
                                {{parseFloat(quote.change.number) / parseFloat(quote.last.number) * 100 | round(2,
                                true)}}%
                            </td>
                            <td class="text-right">{{quote.open | dtnNumber | round(4, true)}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.previous | dtnNumber | round(4, true)}}</td>
                            <td class="text-right">{{quote.high | dtnNumber | round(4, true)}}</td>
                            <td class="text-right">{{quote.low | dtnNumber | round(4, true)}}</td>

                            <td v-if="showAllColumns" class="text-right">{{quote.openInterest}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.ask | dtnNumber | round(4, true)}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.askDateTime | time}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.bid | dtnNumber | round(4, true)}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.bidDateTime | time}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.settlePrice | dtnNumber | round(4, true)}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.contractHigh | dtnNumber | round(4, true)}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.contractLow | dtnNumber | round(4, true)}}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.cumVolume }}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.expirationDate }}</td>
                            <td v-if="showAllColumns" class="text-right">{{quote.settleDate }}</td>


                            <td class="text-right">{{quote.tradeDateTime | time}}</td>
                            <td>
                                <button v-on:click="showModal(quote.symbol.tickerSymbol)" class="btn btn-table-link"><i
                                        class="fas fa-chart-line" title="View Chart"></i></button>
                            </td>
                        </tr>
                        </tbody>
                    </template>

                </table>
            </div>

            <modal name="dtn-futures-chart" height="auto" :adaptive="true" class="dtn-modal"
                   @before-open="onModalOpen"
                   @opened="afterModalOpen"
                   @closed="onModalClose">
                <div class="dtn-modal-content">
                    <div class="modal-controls">
                        <button class="btn btn-link" v-on:click="closeModal()"><i class="fas fa-times" title="Close"></i></button>

                    </div>
                    <div id="futuresChart"></div>
                </div>

            </modal>



        </div>


    </div>
</template>

<script>
    import axios from 'axios';
    import _ from 'lodash';

    import moment from 'moment';
    // import DtnFuturesModal from "./DtnFuturesModal";
    // var moment = require('moment');


    export default {
        name: 'HelloWorld',
        components: {
        },
        props: ['info'],

        data() {
            return {
                priceHistory: {},
                quotes: {
                    corn: {
                        symbol: '@C`## 10',
                        label: "Corn",
                        data: {}
                    },
                    soybeans: {
                        symbol: '@S`## 10',
                        label: "Soybeans",
                        data: {}
                    }
                },
                showAllColumns: false,
                symbol: "",
                widget: null,
                status: {
                    ready: false,
                    errors: null
                }
            }
        },
        mounted() {
            let dtnFuturesScript = document.createElement('script');
            dtnFuturesScript.setAttribute('src', '/dtn-widget-scripts/dtn-futures-chart-widget.js');
            document.head.appendChild(dtnFuturesScript);
        },
        created() {
            console.log('this.info', this.info);
            console.log('this.quotes', this.quotes);


            let quotes = this.quotes;
            let self = this;
            Object.keys(this.quotes).forEach(function (item) {
                console.log(item); // key
                console.log(quotes[item]); // value
                self.getQuotes(quotes[item]);
            });
            // for (var item in this.quotes) {
            //     if (!item.hasOwnProperty(key)) continue;
            //     console.log('item', this.quotes[item]);
            //     this.getQuotes('@S`## 10');
            //
            // }
            // this.quotes.forEach(function(item){
            //     console.log('item', item);
            //
            // });
            // this.getPriceHistory();


        },
        methods: {
            percentchange() {
                return '12'

            },
            removeMonth(data, month) {
                console.log('data', data);
                var sep = _.remove(data, function(item) {
                    return item.month.indexOf(month) !== -1;
                });
                return data;
            },
            getQuotes(item) {

                let symbol = encodeURIComponent(item.symbol);
                axios.get('https://api.dtn.com/markets/symbols/' + symbol + '/quotes', {
                    params: {
                        apikey: 'YFI8EtJuHjVeKclu68ZHhazpmIFtjG8n',
                    }
                })
                    .then(response => {
                        if (item.label === "Soybeans") {
                            item.data = this.removeMonth(response.data, "Sep");
                        } else {
                            item.data = response.data;
                        }
                        this.status.ready = true;
                    })
                    .catch(e => {
                        console.log({e})
                        console.log(e);
                        this.status.errors.push(e.response.data)
                    })
            },
            instantiateFuturesWidget(symbol) {
                console.log('symbol', symbol);
                var self = this;
                window.dtn.futures.createFuturesChartWidget({

                    "apiKey": "YFI8EtJuHjVeKclu68ZHhazpmIFtjG8n",
                    "container": "#futuresChart",
                    "units": "Imperial",
                    "symbol": symbol,
                    "showSymbol": true,
                    "showSymbolDescription": true,
                    "defaultChartType": "LINE",
                    "defaultChartInterval": "M",
                    "callbacks": {
                        "onChartDisplayChange": "",
                        "onChartUpdate": ""
                    }
                })
                    .then(function (widget) {
                        console.log("done!");
                        console.log(widget);
                        self.widget = widget;
                    })

            },
            beforeOpen() {
                console.log("before open");

            },
            onModalOpen() {
                // Add a class to the body to lower the z-index for sticky headers
                document.body.classList.add('vue-modal-showing');
            },
            onModalClose() {
                // Remove the class to the body to lower the z-index for sticky headers
                document.body.classList.remove('vue-modal-showing');
            },
            afterModalOpen() {
                console.log("modal is open");
                this.instantiateFuturesWidget(this.symbol);

            },
            show(symbol) {
                console.log('symbol', symbol);
                this.symbol = symbol;
                this.$refs.futuresModal.open();

                if (this.widget) {
                    console.log("already instantiated");
                    console.log("setting symbol to");
                    console.log(symbol);
                    this.widget.setSymbol(symbol);
                } else {
                    this.instantiateFuturesWidget(symbol);
                }


            },
            showModal(symbol) {
                console.log('showing modal for symbol:');
                console.log(symbol);
                this.symbol = symbol;
                this.$modal.show('dtn-futures-chart');
            },
            closeModal() {
                this.$modal.hide('dtn-futures-chart');
            },
            hide() {
                this.$modal.hide('dtn-futures-chart');
            },
            showFuturesChart(symbol) {
                console.log('showing futures chart');
                window.dtn.futures.createFuturesChartWidget({

                    "apiKey": "YFI8EtJuHjVeKclu68ZHhazpmIFtjG8n",
                    "container": "#futuresChart",
                    "units": "Imperial",
                    "symbol": "@C@1",
                    "showSymbol": true,
                    "showSymbolDescription": true,
                    "defaultChartType": "LINE",
                    "defaultChartInterval": "M",
                    "callbacks": {
                        "onChartDisplayChange": "",
                        "onChartUpdate": ""
                    }


                });

            },
            getPriceHistory() {
                axios.get('https://api.dtn.com/markets/price-history', {
                    params: {
                        apikey: 'oViXqthDGFNVw3IvAsnKrFG1AcfPyA6b',
                        interval: 'M',
                        symbol: '@C9H'
                    }
                })
                    .then(response => {
                        console.log({response});
                        this.priceHistory = response.data;
                        this.status.ready = true;
                    })
                    .catch(e => {
                        console.log({e})
                        console.log(e);
                        this.status.errors.push(e.response.data)
                    })
            },
            upDown: function(value) {
                console.log(value);
                let parsedValue = value;
                if (!_.isUndefined(value.number)) {
                    parsedValue = value.number;
                }
                if (parsedValue > 0) {
                    return "text-success";
                }
                if (parsedValue < 0) {
                    return "text-danger";
                }
                return "";
            }
        },
        computed: {

        },
        filters: {
            dtnNumber: function (value) {
                if (!value) {
                    return '';
                }
                if (!_.isUndefined(value.number)) {
                    return value.number / 100;
                } else {
                    return value;
                }

                // return value.number;
                // return value.number ? value.number : '';
            },
            date: function (value) {
                return moment(String(value)).format('MMM Do, YYYY')
            },
            time: function (value) {
                return moment(String(value)).format('h:mm:ss')
            },
            round: function (value, accuracy, keep) {
                if (typeof value !== 'number') return value;

                var fixed = value.toFixed(accuracy);

                return keep ? fixed : +fixed;
            }

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h1, h2 {
        color: red;
        font-weight: normal;
    }
</style>
