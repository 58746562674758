// import "@babel/polyfill";
import "@babel/polyfill/noConflict";
import 'bootstrap';
import 'bootstrap/js/dist/util';
import './app.scss'
window.jQuery = require('jquery');
import './js/script.js'

import Vue from 'vue'
import Components from './components'

Vue.config.productionTip = false

import VModal from 'vue-js-modal'
Vue.use(VModal)

// Check for an #app element before starting vue
if (document.getElementById('app')) {
    new Vue({
        el: '#app',
        delimiters: ['${', '}']
    });
}
