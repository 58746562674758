var $ = require('jquery');
console.log('hi');

import Flickity from 'flickity';

function siteHeader() {

   var prevScrollpos = $(window).scrollTop();
   var windowWidth = $(window).width();
   var navHeight = $('.site-header').outerHeight();

   $(window).on('resize', function(){
      windowWidth = $(window).width();
   });

   $(window).on('scroll', function(){

      if (!$('body').hasClass('open')) {

         var currentScrollPos = $(window).scrollTop();

         if (currentScrollPos > prevScrollpos && currentScrollPos > navHeight){

            $('.site-header').removeClass('nav-down').addClass('nav-up');

            if (windowWidth > 767 ) {
               $('.site-header .dropdown-menu').removeClass('show');
            }

         } else {

            if (currentScrollPos + $(window).height() < $(document).height()) {

               $('.site-header').removeClass('nav-up').addClass('nav-down');

            }

         }

         prevScrollpos = currentScrollPos;

      }
      
   });
}

function overlayScroll() {

   var prevMenuPos = $('.navbar-nav').offset().top;

   $('.custom-collapse.overlay .overlay-menu').on('scroll', function(){
      var currentMenuPos = $('.navbar-nav').offset().top;
      // var adminBar = $('.adminbar').height();
      var menuOffset = (prevMenuPos - $(window).scrollTop());
      // console.log(menuOffset);
      if (menuOffset < 80) {
         $('.navbar-toggler').addClass('navbar-toggle-fade');
      } else {
         $('.navbar-toggler').removeClass('navbar-toggle-fade');
      }
      prevMenuPos = currentMenuPos;
   });

   $('.nav-item-parent').on('click', function(e){
      $(this).toggleClass('expanded');
      if (!$(this).hasClass('expanded')) {
         $('.custom-collapse.overlay .overlay-menu').on('scroll', function(){
            var currentMenuPos = $('.navbar-nav').offset().top;
            var menuOffset = (prevMenuPos - $(window).scrollTop());
            if (menuOffset <= 100) {
               $('.navbar-toggler').addClass('navbar-toggle-fade');
            } else {
               $('.navbar-toggler').removeClass('navbar-toggle-fade');
            }
            prevMenuPos = currentMenuPos;
         });
      }
   });
}

function mobileMenuOverlay() {
   $('.navbar-toggler').on('click', function(){
      var toggleEl = $(this);
      if (toggleEl.attr('aria-expanded') == 'false') {
         toggleEl.attr('aria-expanded', 'true');
      } else {
         toggleEl.attr('aria-expanded', 'false');
      }
      $('.custom-collapse').toggleClass('hide');
      $('body').toggleClass('open');
      if(!$('body').hasClass('open')) {
         $(this).removeClass('navbar-toggle-fade');
      }
   });
}

function cardRedundantClick() {
   var cards = document.querySelectorAll('.news-card, .podcast-card, .child-card');
   Array.prototype.forEach.call(cards, card => {  
       var down, up, link = card.querySelector('h5 a');
       card.onmousedown = () => down = +new Date();
       card.onmouseup = () => {
           up = +new Date();
           if ((up - down) < 200) {
               link.click();
           }
       }
   });
}

function servicesFormSubmit() {
   $("#services").change(function() {
      $("#location-filter-form").submit();
   });
}

// function productsFormSubmit() {
//    $("#products").change(function() {
//       $("#location-filter-form").submit();
//    });
// }

function mobileTabbedContent() {
   
   var navwidth = 0;
   var morewidth = $('.nav-tabs-responsive .more').outerWidth(true);

   $('.nav-tabs-responsive > li:not(.more)').each(function() {
      navwidth += $(this).outerWidth( true );
   });

   var availablespace = $('.nav-tabs-responsive').outerWidth(true) - morewidth;

   if (navwidth > availablespace) {

      var lastItem = $('.nav-tabs-responsive > li:not(.more)').last();
      lastItem.attr('data-width', lastItem.outerWidth(true));
      lastItem.prependTo($('.nav-tabs-responsive .more div ul'));
      mobileTabbedContent();

   } else {

      var firstMoreElement = $('.nav-tabs-responsive li.more li').first();
      if (navwidth + firstMoreElement.data('width') < availablespace) {
         firstMoreElement.insertBefore($('.nav-tabs-responsive .more'));
      }
   }

   if ($('.more li').length > 0) {
      $('.more').css('display','inline-block');
   } else {
      $('.more').css('display','none');
   }
}

function quadrantToggle() {
   var buttons = document.querySelectorAll('.quadrant-links');
   var tabContent = document.querySelectorAll('.quadrant-description');
   // console.log(tabContent);
   if (buttons && tabContent) {
      for (var i = 0; i < buttons.length; i++) {
         buttons[i].addEventListener("click", function(e){

            var currElem = e.currentTarget;
            var currElemID = currElem.getAttribute('aria-controls');
            var currElemAriaExpanded = currElem.getAttribute('aria-expanded');

            if (currElemAriaExpanded == 'false') {
               currElem.setAttribute('aria-expanded', 'true');
            }

            var control = $(this);
            checkOthers(control[0]);

            var currElemAriaControls = currElem.getAttribute('aria-controls');
            // var currContent = document.getElementById(currElem);
            for (var x = 0; x < tabContent.length; x++) {
               var contentElem = tabContent[x];
               var contentId = contentElem.getAttribute('id');
               // console.log(contentId);
               // var currContent = document.getElementById(currElem);
               if (contentId === currElemAriaControls) {
                  if (contentElem.style.display === "none") {
                     contentElem.style.display = "block";
                     contentElem.setAttribute('aria-hidden', 'false');
                  }
               } else {
                  contentElem.style.display = "none";
                  contentElem.setAttribute('aria-hidden', 'true');
               }
            }
         });
      }
   }
}

function checkOthers(elem) {
   var buttons = document.querySelectorAll('.quadrant-links');
   for (var i = 0; i < buttons.length; i++) {
      if (buttons[i] != elem) {
         if ((buttons[i]).getAttribute('aria-expanded') == 'true') {
            buttons[i].setAttribute('aria-expanded', 'false');
            // var content = $(buttons[i]).attr('aria-controls');
            // $('#' + content).attr('aria-hidden', 'true');
            // $('#' + content).css('display', 'none');
         }
      }
   }
};

$(document).ready(function(){
   console.log('ready');
   siteHeader();
   mobileMenuOverlay();
   overlayScroll();
   cardRedundantClick();
   servicesFormSubmit();
   // productsFormSubmit();
   quadrantToggle();

   // Add Class to body tag on IE 10, MSEdge, and IE 11
   if (window.navigator.userAgent.indexOf("MSIE") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      $("body").addClass("ie");
   }

   if (navigator.userAgent.indexOf("MSIE") > 0) {
      var ieError = "<p>You are using an unsupported version of Internet Explorer.</p><p>Please upgrade to Internet Explorer 11 or use a different web browser.</p>"
      document.write(ieError);
   }

   $(window).on('resize', function() {
     mobileTabbedContent();
   }).resize();

   $('.quadrant-description .expand, .cb-accordion .expand, .cb-employees .expand').on('click', function(){
      var str = $(this).attr('aria-label');
      var res = '';
      var spaceChar = str.indexOf(" ");
      var reqstr = str.substring(0,spaceChar);
      // console.log(reqstr);
      if (reqstr == 'Expand') {
         res = str.replace('Expand', 'Minimize');
         str = $(this).attr('aria-label', res);
      }
      if (reqstr == 'Minimize') {
         res = str.replace('Minimize', 'Expand');
         str = $(this).attr('aria-label', res);
      }
   });

   $("a.quadrant-links").on('click', function(event) {

    // Make sure this.hash has a value before overriding default behavior
    if (this.hash !== "") {
      // Prevent default anchor click behavior
      event.preventDefault();

      // Store hash
      var hash = this.hash;

      // Using jQuery's animate() method to add smooth page scroll
      // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
      $('html, body').animate({
        scrollTop: $(hash).offset().top
      }, 800, function(){
   
        // Add hash (#) to URL when done scrolling (default click behavior)
        window.location.hash = hash;
      });
    } // End if
  });
});
