<template>
    <div class="dtn">

        <div class="alert alert-danger" v-if="status.error" v-on:click="status.showFullError = !status.showFullError">
            {{status.error.simple}}
            <pre v-if="status.showFullError">{{status.error.full}}</pre>
        </div>

        <form v-on:change.prevent="setSavedLocation(selectedLocation)" class="mt-4">
            <div class="form-row">
                <div class="col-12 col-sm-6 col-md-4  form-group">
                    <label class="sr-only" for="location">Location</label>
                    <select class="form-control cash-bids-locations custom-select" v-model="selectedLocation" id="location">
                        <option v-for="location in locations" v-bind:value="location.locationNumber">
                            {{ location.locationName }}
                        </option>
                    </select>
                </div>
                <!-- <div class="col-12 col-sm-4 col-md-3">
                    <button type="submit"
                            class="btn btn-block btn-primary mb-2">
                        Update Location
                    </button>
                </div> -->
            </div>


        </form>

        <div class="loading text-center mt-4" v-if="!status.ready && !status.timeout">
            <p class="lead">Loading Cash Bids</p>
            <div class="spinner">
                <div class="cube1"></div>
                <div class="cube2"></div>
            </div>
        </div>

        <div class="cash-bids-timeout row mt-5" v-if="status.timeout">
            <div class="col-md-6 offset-md-3">
                <div class="alert alert-warning text-center">
                    <p class="lead">The data failed to load in the allocated time.</p>
                    <button v-on:click="getCashBids" class="btn btn-primary mb-1">Try Again</button>
                </div>
            </div>


        </div>

        <div v-if="status.ready">
            <p>
                <strong>Last Updated: {{cashBids.asOfDateTime}}</strong><br>
                <small>
                    Quotes delayed minimum of 10 minutes. Cash bids only an indication and not real-time.
                    Contact your nearest Landus Cooperative location for live bids and contract information.
                </small>

            </p>

            <div v-for="bidGroup in cashBids.cashBids">
                <h2 class="cash-bids-section-heading">{{bidGroup.commodity}}</h2>

                <table class="table table-sm table-striped table-dtn">
                    <thead class="thead-dark">
                    <tr>
                        <th>Delivery Period</th>
                        <th>Bid</th>
                        <th>Change</th>
                        <th>Basis</th>
                        <th>Basis Month</th>
                    </tr>
                    </thead>

                    <tbody >
                    <tr v-for="bid in bidGroup.bids">
                        <td>{{bid.delivery_date}}</td>
                        <td>{{bid.current_bid | round(2, true)}}</td>
                        <td v-bind:class="upDown(bid.bid_change)">{{bid.bid_change | round(2, true)}}</td>
                        <td>{{bid.basis_price | round(2, true)}}</td>
                        <td>{{bid.basis_month}}</td>
                    </tr>
                    </tbody>
                </table>

            </div>

        </div>

        <modal name="cash-bid-details" height="auto" :adaptive="true"  class="dtn-modal"
               @before-open="onModalOpen"
               @opened="afterModalOpen"
               @closed="onModalClose">
            <div class="dtn-modal-content">
                <div class="modal-controls">
                    <button class="btn btn-link" v-on:click="closeModal()"><i class="fas fa-times" title="Close"></i></button>

                </div>
                <div id="cashBidDetails">
                    <h3 class="modal-title">{{currentBid.location_name}} - {{currentBid.commodity}}</h3>
                    <dl class="row cash-bid-details">
                        <dt class="col-sm-3">Delivery Date</dt>
                        <dd class="col-sm-9">{{currentBid.delivery_date}}</dd>
                        <dt class="col-sm-3">Bid</dt>
                        <dd class="col-sm-9">{{currentBid.current_bid | round(2, true)}}</dd>
                        <dt class="col-sm-3">Change</dt>
                        <dd class="col-sm-9">{{currentBid.bid_change | round(2, true)}}</dd>
                        <dt class="col-sm-3">Basis</dt>
                        <dd class="col-sm-9">{{currentBid.basis_price | round(2, true)}}</dd>
                        <dt class="col-sm-3">Last Updated</dt>
                        <dd class="col-sm-9">{{currentBid.asOfDateTime}}</dd>
                    </dl>
                </div>
            </div>

        </modal>


    </div>
</template>

<script>
    import axios from 'axios';
    import _ from 'lodash';

    import moment from 'moment';
    // var moment = require('moment');


    export default {
        name: 'HelloWorld',
        props: ['info'],
        data() {
            return {
                locations: [],
                selectedLocation: null,
                cashBids: {},
                currentBid: {},
                status: {
                    ready: false,
                    error: null,
                    showFullError: false,
                    timeout: false
                }
            }
        },
        created() {
            this.selectedLocation = "1";
            this.selectedLocation = this.getSavedLocation();

            // Get Locations
            this.getLocations();

            // Get Cash Bids
            this.getCashBids();


        },
        methods: {
            getSavedLocation() {
                // let loc = this.$cookies.get('cashBidsLocation');
                let loc = localStorage.getItem('cashBidsLocation');
                if (!loc) {
                    return "1";
                }
                return loc;
            },

            setSavedLocation(loc) {
                console.log('saving location');
                console.log(loc);
                this.selectedLocation = loc;
                this.getCashBids();
                // this.$cookies.set('cashBidsLocation', loc);
                localStorage.setItem('cashBidsLocation', loc);
            },

            getLocations() {
                axios.get('/actions/landus-api-module/proxy/get-locations', {})
                    .then(response => {
                        console.log('response', response);
                        this.locations = response.data.body.payload.details;
                    })
                    .catch(e => {
                        console.log({e});
                        console.log(e);
                        if (e.response) {
                            this.status.error = {simple: e.response, full: e};
                        } else {
                            this.status.error = {simple: e, full: e};

                        }

                    });
            },

            getCashBids() {
                this.cashBids = {};

                this.status = {
                    ready: false,
                    error: null,
                    showFullError: false,
                    timeout: false
                };

                axios.get('/actions/landus-api-module/proxy/get-cash-bids', {
                    params: {
                        location: this.selectedLocation
                    }
                })
                    .then(response => {
                        console.log('response', response);
                        if (response.data.body) {
                            this.cashBids = response.data.body.payload.details;
                            this.status.ready = true;
                        }
                        if (response.data.error) {
                            if (response.data.error.context.errno === 28) {
                                this.status.timeout = true; // show timeout message
                            } else {
                                this.status.error = {simple: response.data.error, full: null};
                            }
                        }
                        
                    })
                    .catch(e => {
                        console.log({e});
                        console.log(e);
                        if (e.response) {
                            this.status.error = {simple: e.response, full: e};
                        } else if (e.message) {
                            this.status.error = {simple: e.message, full: e.stack};
                        } else {
                            this.status.error = {simple: "An unknown error has occurred.", full: null};

                        }
                    });
            },

            // beforeOpen() {
            //     console.log("before open");
            //
            // },
            onModalOpen() {
                // Add a class to the body to lower the z-index for sticky headers
                document.body.classList.add('vue-modal-showing');
            },
            onModalClose() {
                // Remove the class to the body to lower the z-index for sticky headers
                document.body.classList.remove('vue-modal-showing');
            },
            afterModalOpen() {
                console.log("modal is open");
            },
            showModal(bid, commodity) {
                console.log('showing modal for bid:');
                console.log(bid);
                this.currentBid = bid;
                this.currentBid.commodity = commodity;
                this.currentBid.location_name = this.cashBids.location_name;
                this.currentBid.asOfDateTime = this.cashBids.asOfDateTime;
                this.$modal.show('cash-bid-details');
            },
            closeModal() {
                this.$modal.hide('cash-bid-details');
            },
            hide() {
                this.$modal.hide('cash-bid-details');
            },
            upDown: function(value) {
                console.log(value);
                let parsedValue = value;
                if (!_.isUndefined(value.number)) {
                    parsedValue = value.number;
                }
                if (parsedValue > 0) {
                    return "text-success";
                }
                if (parsedValue < 0) {
                    return "text-danger";
                }
                return "";
            }
        },
        filters: {
            date: function(value) {
                return moment(String(value)).format('MMM Do, YYYY')
            },
            round: function (value, accuracy, keep) {
                if (typeof value !== 'number') return value;

                var fixed = value.toFixed(accuracy);

                return keep ? fixed : +fixed;
            }

        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h1, h2 {
        font-weight: normal;
    }
</style>